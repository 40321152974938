@import '../../styles/variables';

$starting-scale: 0.5;
$trans-time: 200ms;
$loader-delay: 100ms;
$height: 56px;

// these properties need to be overridden if the pageLoader or componentLoader class is present
// so they're listed at the top of the page
.loaderIsLoading {
    position: relative;
    height: $height;
}

.componentLoader,
.componentLoaderNoBackground,
.pageLoader {
    width: 0;
    height: 100%;
}

.componentLoader,
.pageLoader {
    background: rgba(255, 255, 255, 0);
    transition: background $trans-time ease-in-out,
        // don't go back to 0 width until the background transition is finished
        width 1ms linear $trans-time;
    &.loaderIsLoading {
        background: rgba(255, 255, 255, 0.9);
        transition: background $trans-time ease-in-out;
    }
}

.componentLoader,
.componentLoaderNoBackground {
    position: absolute;
    bottom: 0;
    left: 0;
}

.pageLoader {
    position: fixed;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0);
    transition: background $trans-time ease-in-out,
        // don't go back to 0 width until the background transition is finished
            width 1ms linear $trans-time;
    &.loaderIsLoading {
        background: rgba(255, 255, 255, 0.9);
        transition: background $trans-time ease-in-out;
    }
}

.loaderIsLoading {
    width: 100%;
    z-index: 2;
    .loaderAnimation {
        opacity: 1;
        transition: opacity $trans-time ease-in-out;
    }
    .loaderAnimationLine {
        animation: expand 1s ease-in-out infinite;
    }
}

.loaderAnimation {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    top: 50%;
    opacity: 0;
}

.loaderAnimationLine {
    transform: scale($starting-scale);
    border-radius: 10px;
    display: inline-block;
    transform-origin: center center;
    margin: 0 2px;
    width: 2px;
    height: $height;
    background: $teal-dark;
}

.loaderAnimationLine:nth-child(1) {
    background: $teal-light;
    animation-delay: $loader-delay;
}

.loaderAnimationLine:nth-child(2) {
    animation-delay: calc(#{$loader-delay} + 180ms);
    background: $teal;
}

.loaderAnimationLine:nth-child(3) {
    animation-delay: calc(#{$loader-delay} + 360ms);
    background: $teal-dark;
}

.loaderAnimationLine:nth-child(4) {
    animation-delay: calc(#{$loader-delay} + 540ms);
    background: $teal-darker;
}

@keyframes expand {
    0% {
        transform: scale($starting-scale);
    }
    25% {
        transform: scale(1);
    }
}
